<template>
  <div class="change_bg">

    <Navbar></Navbar>
    <section>
      <div class="container">
        <div class="manage_link">
          <div class="m_link1">
            <router-link to="/">
              {{$store.state.language.lang[1] }}</router-link>
          </div>
          <div>
            <svg width="8" height="12" viewBox="0 0 8 12" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path fill-rule="evenodd" clip-rule="evenodd"
                    d="M0.106862 0.191111C0.277469 -0.0260253 0.591796 -0.0637446 0.808932 0.106862L7.80893 5.60686C7.92958 5.70165 8.00002 5.84659 8.00002 6.00002C8.00002 6.15345 7.92958 6.29839 7.80893 6.39318L0.808932 11.8932C0.591796 12.0638 0.277469 12.0261 0.106862 11.8089C-0.0637446 11.5918 -0.0260253 11.2775 0.191111 11.1069L6.69073 6.00002L0.191111 0.89318C-0.0260253 0.722574 -0.0637446 0.408246 0.106862 0.191111Z"
                    fill="#89899F"/>
            </svg>
          </div>
          <div class="m_link2">
            <router-link to="/">
              {{$store.state.language.lang[14] }}
            </router-link>
          </div>
        </div>
        <div class="row">
          <div class="col-xl-3">


            <div class="m_sidebar">
              <div class="m_sidebar_title">
               {{$store.state.language.lang[1]}}
              </div>
              <div class="m_sidebar_link">
                <router-link to="/agency/management" class="menu_drops">
                  {{$store.state.language.lang[10]}}
                </router-link>
              </div>
              <div class="m_sidebar_link">
                <router-link to="/central/office" class="menu_drops">
                  {{$store.state.language.lang[9]}}</router-link>
              </div>
              <div class="m_sidebar_link">
                <router-link to="/structure" class="menu_drops">
                  {{$store.state.language.lang[11]}}
                </router-link>
              </div>
              <div class="m_sidebar_link">
                <router-link to="/council" class="menu_drops">
                  {{$store.state.language.lang[12]}}
                </router-link>
              </div>
              <div class="m_sidebar_link">
                <router-link to="/territorial" class="menu_drops">
                  {{$store.state.language.lang[13]}}
                </router-link>
              </div>

              <div class="m_sidebar_link">
                <router-link to="/regulations" class="menu_drops">
                  {{$store.state.language.lang[15]}}
                </router-link>
              </div>
              <div class="m_sidebar_link">
                <router-link to="/organization" class="menu_drops">
                  {{$store.state.language.lang[16]}}
                </router-link>
              </div>
              <div class="m_sidebar_link">
                <router-link to="/activity" class="menu_drops">
                  {{$store.state.language.lang[17]}}
                </router-link>
              </div>
              <div class="m_sidebar_link">
                <router-link to="/museum" class="menu_drops">
                  {{$store.state.language.lang[18]}}
                </router-link>
              </div>
              <div class="m_sidebar_link">
                <router-link to="/vacancy" class="menu_drops">
                  {{$store.state.language.lang[19]}}
                </router-link>
              </div>

            </div>
          </div>
          <div class="col-xl-9 col-12">
            <div class="m_body_title">
              <h2>
                {{$store.state.language.lang[14] }}
              </h2>
            </div>
            <MediaSide :name="$store.state.language.lang[14]"></MediaSide>
            <div>
              <form @submit.prevent="getSearch()">
                <div class="dec_input_group">
                  <div class="search_inputs">
                    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path fill-rule="evenodd" clip-rule="evenodd"
                            d="M9.09091 1.81818C5.07429 1.81818 1.81818 5.07429 1.81818 9.09091C1.81818 13.1075 5.07429 16.3636 9.09091 16.3636C13.1075 16.3636 16.3636 13.1075 16.3636 9.09091C16.3636 5.07429 13.1075 1.81818 9.09091 1.81818ZM0 9.09091C0 4.07014 4.07014 0 9.09091 0C14.1117 0 18.1818 4.07014 18.1818 9.09091C18.1818 11.2739 17.4124 13.2771 16.1299 14.8443L19.7337 18.4481C20.0888 18.8031 20.0888 19.3787 19.7337 19.7337C19.3787 20.0888 18.8031 20.0888 18.4481 19.7337L14.8443 16.1299C13.2771 17.4124 11.2739 18.1818 9.09091 18.1818C4.07014 18.1818 0 14.1117 0 9.09091Z"
                            fill="#01011C"/>
                    </svg>
                    <input type="text" v-model="searchs" :placeholder="$store.state.language.lang[65]">
                  </div>
                  <div class="buttons_search">
                    <button>Search</button>
                  </div>
                </div>
              </form>
            </div>
              <div class="dec_text">
                {{ $store.state.language.lang[66] }}: {{ $store.state.docs1.total }}
              </div>
            <div class="dec_card" v-for="(item,index) in $store.state.docs1.documents" :key="index">
              <div class="dec_card_main">
                <div class="loga_dec">

                  <svg width="54" height="54" viewBox="0 0 54 54" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M17.5671 38.0808H18.6812C19.3931 38.0808 19.916 38.217 20.25 38.4895C20.5884 38.762 20.7576 39.1772 20.7576 39.7354C20.7576 40.1528 20.6675 40.489 20.4873 40.7439C20.3071 40.9988 20.0435 41.1855 19.6963 41.3042C19.3491 41.4185 18.9272 41.4756 18.4307 41.4756H17.5671V38.0808Z" fill="url(#paint0_linear_678_10617)"/>
                    <path d="M30.0916 39.5903C30.342 40.1001 30.4673 40.7505 30.4673 41.5415C30.4673 42.7148 30.1794 43.5981 29.6038 44.1914C29.0281 44.7847 28.158 45.0813 26.9934 45.0813H25.8728V38.0808H27.2241C27.9272 38.0808 28.5183 38.2061 28.9973 38.4565C29.4807 38.7026 29.8455 39.0806 30.0916 39.5903Z" fill="url(#paint1_linear_678_10617)"/>
                    <path fill-rule="evenodd" clip-rule="evenodd" d="M6.75 3.375C6.75 1.51104 8.26104 0 10.125 0H30.375V10.9688C30.375 13.2987 32.2638 15.1875 34.5938 15.1875H47.25V50.625C47.25 52.489 45.739 54 43.875 54H10.125C8.26104 54 6.75 52.489 6.75 50.625V3.375ZM21.509 37.5337C20.9377 37.0239 20.0303 36.769 18.7866 36.769H15.9851V46.4062H17.5671V42.7939H18.5955C19.2986 42.7939 19.8896 42.7104 20.3687 42.5435C20.852 42.3765 21.241 42.1501 21.5354 41.8645C21.8298 41.5745 22.0408 41.2427 22.1682 40.8691C22.3 40.4912 22.366 40.0935 22.366 39.676C22.366 38.7576 22.0803 38.0435 21.509 37.5337ZM31.5088 44.2178C31.9131 43.4883 32.1152 42.5808 32.1152 41.4954C32.1152 40.4626 31.9175 39.5969 31.522 38.8982C31.1265 38.1951 30.5662 37.6655 29.8411 37.3096C29.116 36.9492 28.2546 36.769 27.2571 36.769H24.2908V46.4062H26.967C28.0525 46.4062 28.9797 46.2239 29.7488 45.8591C30.5178 45.49 31.1045 44.9429 31.5088 44.2178ZM34.1917 46.4062H35.7605V42.4314H39.386V41.1064H35.7605V38.1006H39.6365V36.769H34.1917V46.4062Z" fill="url(#paint2_linear_678_10617)"/>
                    <path d="M32.0625 10.9688V0.253447C32.4108 0.39661 32.7355 0.598196 33.0216 0.852494L46.1172 12.4931C46.44 12.78 46.7009 13.1225 46.8906 13.5H34.5938C33.1958 13.5 32.0625 12.3667 32.0625 10.9688Z" fill="url(#paint3_linear_678_10617)"/>
                    <defs>
                      <linearGradient id="paint0_linear_678_10617" x1="16.125" y1="-2.46128e-07" x2="42" y2="59.25" gradientUnits="userSpaceOnUse">
                        <stop stop-color="#3C3C55" stop-opacity="0.29"/>
                        <stop offset="1" stop-color="#3C3C55" stop-opacity="0.49"/>
                      </linearGradient>
                      <linearGradient id="paint1_linear_678_10617" x1="16.125" y1="-2.46128e-07" x2="42" y2="59.25" gradientUnits="userSpaceOnUse">
                        <stop stop-color="#3C3C55" stop-opacity="0.29"/>
                        <stop offset="1" stop-color="#3C3C55" stop-opacity="0.49"/>
                      </linearGradient>
                      <linearGradient id="paint2_linear_678_10617" x1="16.125" y1="-2.46128e-07" x2="42" y2="59.25" gradientUnits="userSpaceOnUse">
                        <stop stop-color="#3C3C55" stop-opacity="0.29"/>
                        <stop offset="1" stop-color="#3C3C55" stop-opacity="0.49"/>
                      </linearGradient>
                      <linearGradient id="paint3_linear_678_10617" x1="16.125" y1="-2.46128e-07" x2="42" y2="59.25" gradientUnits="userSpaceOnUse">
                        <stop stop-color="#3C3C55" stop-opacity="0.29"/>
                        <stop offset="1" stop-color="#3C3C55" stop-opacity="0.49"/>
                      </linearGradient>
                    </defs>
                  </svg>

                </div>
                <div class="title_dec">
                    <div class="dec_title">{{item.title}}</div>
                  <div class="dec_num">№ {{item.document_number}}</div>
                </div>

              </div>
              <div class="download">
                <a :href="item.file">
                  <div class="link_docs">
                    <svg width="22" height="21" viewBox="0 0 22 21" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path d="M21.084 13.667C21.084 13.2528 20.7482 12.917 20.334 12.917C19.9198 12.917 19.584 13.2528 19.584 13.667H21.084ZM2.41797 13.667C2.41797 13.2528 2.08218 12.917 1.66797 12.917C1.25376 12.917 0.917969 13.2528 0.917969 13.667H2.41797ZM17.2793 9.80733C17.5722 9.51444 17.5722 9.03957 17.2793 8.74667C16.9864 8.45378 16.5115 8.45378 16.2186 8.74667L17.2793 9.80733ZM11.036 14.99L10.5056 15.5203C10.6463 15.661 10.8371 15.74 11.036 15.74C11.2349 15.74 11.4256 15.661 11.5663 15.5203L11.036 14.99ZM5.8533 8.74667C5.56041 8.45378 5.08553 8.45378 4.79264 8.74667C4.49975 9.03957 4.49975 9.51444 4.79264 9.80733L5.8533 8.74667ZM11.786 1.677C11.786 1.26279 11.4502 0.927002 11.036 0.927002C10.6218 0.927002 10.286 1.26279 10.286 1.677H11.786ZM10.286 14.973C10.286 15.3872 10.6218 15.723 11.036 15.723C11.4502 15.723 11.786 15.3872 11.786 14.973H10.286ZM19.584 13.667V16.714H21.084V13.667H19.584ZM19.584 16.714C19.584 17.9568 18.5768 18.964 17.334 18.964V20.464C19.4052 20.464 21.084 18.7852 21.084 16.714H19.584ZM17.334 18.964H4.66797V20.464H17.334V18.964ZM4.66797 18.964C3.42518 18.964 2.41797 17.9568 2.41797 16.714H0.917969C0.917969 18.7852 2.59675 20.464 4.66797 20.464V18.964ZM2.41797 16.714V13.667H0.917969V16.714H2.41797ZM16.2186 8.74667L10.5056 14.4597L11.5663 15.5203L17.2793 9.80733L16.2186 8.74667ZM11.5663 14.4597L5.8533 8.74667L4.79264 9.80733L10.5056 15.5203L11.5663 14.4597ZM10.286 1.677V14.973H11.786V1.677H10.286Z" fill="#00B57F"/>
                    </svg>
                  </div>
                </a>
              </div>
            </div>
            <div class="dec_card" v-for="(item,index) in $store.state.docs1.results" :key="index">
              <div class="dec_card_main">
                <div class="loga_dec">

                  <svg width="54" height="54" viewBox="0 0 54 54" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M17.5671 38.0808H18.6812C19.3931 38.0808 19.916 38.217 20.25 38.4895C20.5884 38.762 20.7576 39.1772 20.7576 39.7354C20.7576 40.1528 20.6675 40.489 20.4873 40.7439C20.3071 40.9988 20.0435 41.1855 19.6963 41.3042C19.3491 41.4185 18.9272 41.4756 18.4307 41.4756H17.5671V38.0808Z" fill="url(#paint0_linear_678_10617)"/>
                    <path d="M30.0916 39.5903C30.342 40.1001 30.4673 40.7505 30.4673 41.5415C30.4673 42.7148 30.1794 43.5981 29.6038 44.1914C29.0281 44.7847 28.158 45.0813 26.9934 45.0813H25.8728V38.0808H27.2241C27.9272 38.0808 28.5183 38.2061 28.9973 38.4565C29.4807 38.7026 29.8455 39.0806 30.0916 39.5903Z" fill="url(#paint1_linear_678_10617)"/>
                    <path fill-rule="evenodd" clip-rule="evenodd" d="M6.75 3.375C6.75 1.51104 8.26104 0 10.125 0H30.375V10.9688C30.375 13.2987 32.2638 15.1875 34.5938 15.1875H47.25V50.625C47.25 52.489 45.739 54 43.875 54H10.125C8.26104 54 6.75 52.489 6.75 50.625V3.375ZM21.509 37.5337C20.9377 37.0239 20.0303 36.769 18.7866 36.769H15.9851V46.4062H17.5671V42.7939H18.5955C19.2986 42.7939 19.8896 42.7104 20.3687 42.5435C20.852 42.3765 21.241 42.1501 21.5354 41.8645C21.8298 41.5745 22.0408 41.2427 22.1682 40.8691C22.3 40.4912 22.366 40.0935 22.366 39.676C22.366 38.7576 22.0803 38.0435 21.509 37.5337ZM31.5088 44.2178C31.9131 43.4883 32.1152 42.5808 32.1152 41.4954C32.1152 40.4626 31.9175 39.5969 31.522 38.8982C31.1265 38.1951 30.5662 37.6655 29.8411 37.3096C29.116 36.9492 28.2546 36.769 27.2571 36.769H24.2908V46.4062H26.967C28.0525 46.4062 28.9797 46.2239 29.7488 45.8591C30.5178 45.49 31.1045 44.9429 31.5088 44.2178ZM34.1917 46.4062H35.7605V42.4314H39.386V41.1064H35.7605V38.1006H39.6365V36.769H34.1917V46.4062Z" fill="url(#paint2_linear_678_10617)"/>
                    <path d="M32.0625 10.9688V0.253447C32.4108 0.39661 32.7355 0.598196 33.0216 0.852494L46.1172 12.4931C46.44 12.78 46.7009 13.1225 46.8906 13.5H34.5938C33.1958 13.5 32.0625 12.3667 32.0625 10.9688Z" fill="url(#paint3_linear_678_10617)"/>
                    <defs>
                      <linearGradient id="paint0_linear_678_10617" x1="16.125" y1="-2.46128e-07" x2="42" y2="59.25" gradientUnits="userSpaceOnUse">
                        <stop stop-color="#3C3C55" stop-opacity="0.29"/>
                        <stop offset="1" stop-color="#3C3C55" stop-opacity="0.49"/>
                      </linearGradient>
                      <linearGradient id="paint1_linear_678_10617" x1="16.125" y1="-2.46128e-07" x2="42" y2="59.25" gradientUnits="userSpaceOnUse">
                        <stop stop-color="#3C3C55" stop-opacity="0.29"/>
                        <stop offset="1" stop-color="#3C3C55" stop-opacity="0.49"/>
                      </linearGradient>
                      <linearGradient id="paint2_linear_678_10617" x1="16.125" y1="-2.46128e-07" x2="42" y2="59.25" gradientUnits="userSpaceOnUse">
                        <stop stop-color="#3C3C55" stop-opacity="0.29"/>
                        <stop offset="1" stop-color="#3C3C55" stop-opacity="0.49"/>
                      </linearGradient>
                      <linearGradient id="paint3_linear_678_10617" x1="16.125" y1="-2.46128e-07" x2="42" y2="59.25" gradientUnits="userSpaceOnUse">
                        <stop stop-color="#3C3C55" stop-opacity="0.29"/>
                        <stop offset="1" stop-color="#3C3C55" stop-opacity="0.49"/>
                      </linearGradient>
                    </defs>
                  </svg>

                </div>
                <div class="title_dec">
                  <div class="dec_title">{{item.title}}</div>
                  <div class="dec_num">№ {{item.document_number}}</div>
                </div>

              </div>
              <div class="download">
                <a :href="item.file">
                  <div class="link_docs">
                    <svg width="22" height="21" viewBox="0 0 22 21" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path d="M21.084 13.667C21.084 13.2528 20.7482 12.917 20.334 12.917C19.9198 12.917 19.584 13.2528 19.584 13.667H21.084ZM2.41797 13.667C2.41797 13.2528 2.08218 12.917 1.66797 12.917C1.25376 12.917 0.917969 13.2528 0.917969 13.667H2.41797ZM17.2793 9.80733C17.5722 9.51444 17.5722 9.03957 17.2793 8.74667C16.9864 8.45378 16.5115 8.45378 16.2186 8.74667L17.2793 9.80733ZM11.036 14.99L10.5056 15.5203C10.6463 15.661 10.8371 15.74 11.036 15.74C11.2349 15.74 11.4256 15.661 11.5663 15.5203L11.036 14.99ZM5.8533 8.74667C5.56041 8.45378 5.08553 8.45378 4.79264 8.74667C4.49975 9.03957 4.49975 9.51444 4.79264 9.80733L5.8533 8.74667ZM11.786 1.677C11.786 1.26279 11.4502 0.927002 11.036 0.927002C10.6218 0.927002 10.286 1.26279 10.286 1.677H11.786ZM10.286 14.973C10.286 15.3872 10.6218 15.723 11.036 15.723C11.4502 15.723 11.786 15.3872 11.786 14.973H10.286ZM19.584 13.667V16.714H21.084V13.667H19.584ZM19.584 16.714C19.584 17.9568 18.5768 18.964 17.334 18.964V20.464C19.4052 20.464 21.084 18.7852 21.084 16.714H19.584ZM17.334 18.964H4.66797V20.464H17.334V18.964ZM4.66797 18.964C3.42518 18.964 2.41797 17.9568 2.41797 16.714H0.917969C0.917969 18.7852 2.59675 20.464 4.66797 20.464V18.964ZM2.41797 16.714V13.667H0.917969V16.714H2.41797ZM16.2186 8.74667L10.5056 14.4597L11.5663 15.5203L17.2793 9.80733L16.2186 8.74667ZM11.5663 14.4597L5.8533 8.74667L4.79264 9.80733L10.5056 15.5203L11.5663 14.4597ZM10.286 1.677V14.973H11.786V1.677H10.286Z" fill="#00B57F"/>
                    </svg>
                  </div>
                </a>
              </div>
            </div>

            <div class="pagenation">
              <paginate
                  :page-count="$store.state.docs1.total_pages"
                  :page-range="3"
                  :margin-pages="2"
                  :click-handler="clickCallback"
                  :prev-text="'<'"
                  :next-text="'>'"
                  :container-class="'pagination'"
                  :page-class="'page-item'"
              >
              </paginate>
            </div>
          </div>
        </div>
      </div>
    </section>
    <Footer></Footer>
  </div>
</template>
<script>
import Navbar from "../Header/Navbar";
import Footer from "../Header/Footer";
import MediaSide from '../MediaSide'

export default {
  components: {
    MediaSide,
    Footer,
    Navbar
  },
  data() {
    return {
      isActive_m: false,
     searchs:'',

      counts:1
    }
  },
  mounted() {
    this.$store.dispatch('docs',{
      "pagecounts":this.counts
    })
  },
  methods: {
  clickCallback(pageNum){
    this.$store.dispatch('docsn1',{
      "pagecounts":pageNum
    })
  },
    nextPage(){
      this.counts=this.counts+1
      this.$store.dispatch('docsn1',{
        "pagecounts":this.counts
      })
    },
    prevPage(){
      if(this.counts>1){
        this.counts=this.counts-1
        this.$store.dispatch('docsn1',{
          "pagecounts":this.counts
        })
      }
    },
    percount(item){
      this.counts=item

      this.$store.dispatch('docsn1',{
        "pagecounts":this.counts
      })
    },
    getSearch(){
      this.$store.state.docsn1=[]
      let a=""
      if(isNaN(this.searchs)==false){
        a={
          "name":`number=${this.searchs}`
        }
      }

      if(isNaN(this.searchs)==true){
        a={

          "name": `doc_name=${this.searchs}`
        }
      }


      this.$store.dispatch('docs1',a)
    }
  }
}
</script>
